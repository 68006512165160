<template>
  <div>
    <Cardvideo :item="debates" />
  </div>
</template>
<script>
import axios from "axios";
import Cardvideo from "../components/Cardvideo.vue";

export default {
  components: {
    Cardvideo,
  },
  data() {
    return {
      debates: [],
      URL_API:process.env.VUE_APP_URL_API
    };
  },
  created() {
    this.getDebates();
  },
  methods: {
    getDebates() {
      var self = this;
      axios
        .get(self.URL_API+"api/dabatespagina")
        .then(function (result) {
          self.debates = result.data;
        })
        .catch(function (error) {
          console.log(error);
          self.getDebates()
        });
    },
  },
};
</script>
<style scoped>
.container {
  padding-top: 150px;
}
h1 {
  font-size: 1.5rem;
  margin-top: 30px;
}
p {
  text-align: justify;
}
</style>
