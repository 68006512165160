import Vue from 'vue'
import VueRouter from 'vue-router'
import Historia from '../views/Historia.vue'
import Inicio from '../views/Inicio.vue'
import Antioquia from '../views/Antioquia.vue'
import Ponencias from '../views/Ponencias.vue'
import Leyes from '../views/Leyes.vue'
import Proyectos from '../views/Proyectos.vue'
import Audiencias from '../views/Audiencias.vue'
import Debates from '../views/Debates.vue'
import Noticias from '../views/Noticias.vue'
import Noticia from '../views/Noticia.vue'


Vue.use(VueRouter)

const routes = [
  // {
  //   path: '/',
  //   name: 'Home',
  //   component: Home
  // },
  {
    path: '/',
    name: 'Inicio',
    component: Inicio
  },
  {
    path: '/historia',
    name: 'Historia',
    component: Historia
  },
  {
    path: '/antioquia',
    name: 'Antioquia',
    component: Antioquia
  },
  {
    path: '/ponencias',
    name: 'Ponencias',
    component: Ponencias
  },
  {
    path: '/leyes',
    name: 'Leyes',
    component: Leyes
  },
  {
    path: '/proyectos',
    name: 'Proyectos',
    component: Proyectos
  },
  {
    path: '/audiencias',
    name: 'Audiencias',
    component: Audiencias
  },
  {
    path: '/debates',
    name: 'Debates',
    component: Debates
  },
  {
    path: '/noticias',
    name: 'Noticias',
    component: Noticias
  },
  {
    path: '/noticia/:id',
    name: 'Noticia',
    component: Noticia
  },
 
  // {
  //   path: '/about',
  //   name: 'About',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  // }
]

const router = new VueRouter({
  routes
})

export default router
