<template>
  <div
    class="container"
    :style="this.$route.path != '/' ? ' padding-top: 150px' : ' padding: 20px'"
  >
    <h4 v-if="this.$route.path == '/proyectos'">
      Soy autor y coautor de más de 80 proyectos de ley a lo largo de estos años
      como Representante a la Cámara. ¡Te invito a conocer un poco más de ellos!
    </h4>
    <div class="card" v-for="item in item" :key="item.title">
      <div class="card__header">
        <img
          v-bind:src="URL_API + item.imagen"
          alt="card__image"
          class="card__image"
          width="600"
        />
      </div>
      <div class="card__body">
        <!-- <span class="tag tag-red">Liberal</span> -->
        <h4>{{ item.titulo }}</h4>
        <p>{{ item.contenido }}</p>
      </div>
      <small
        id="small"
        v-if="$route.path == '/' || $route.path == '/noticias'"
        @click="noticia(item.id)"
        ><a rel="noopener noreferrer">Ver más</a></small
      >
      <div v-if="item.imagen2" class="card__footer">
        <div class="user">
          <img
            v-bind:src="URL_API + item.imagen2"
            style="width: 30px; height: 30px"
            alt="user__image"
            class="user__image"
          />
          <div class="user__info">
            <h5>Julian Peinado</h5>
            <small v-if="ruta == '/leyes'"
              ><a :href="item.url" target="_blank" rel="noopener noreferrer"
                >Ver ley</a
              ></small
            >
            <small v-else
              ><a :href="item.url" target="_blank" rel="noopener noreferrer"
                >Ver proyecto de ley</a
              ></small
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    item: [],
  },
  data() {
    return {
      URL_API: process.env.VUE_APP_URL_API,
      ruta : this.$route.path
    };
  },
  created() {},
  methods: {
    noticia(id) {
      this.$router.push({ name: "Noticia", params: { id: id } });
    },
  },
};
</script>
<style scoped>
/* .container {
  padding-top: 150px;
} */
h1 {
  font-size: 1.5rem;
  margin-top: 30px;
}
p {
  text-align: justify;
}
a {
  text-decoration: none;
  text-align: left;
}

#small {
  background-color: #df2c10;
  color: white;
  padding: 10px;
  cursor: pointer;
  position: absolute;
  bottom: 0px;
  width: 100%;
}
/********************************************/
@import url("https://fonts.googleapis.com/css2?family=Quicksand:wght@300..700&display=swap");

*,
*::before,
*::after {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

/* body {
  font-family: "Quicksand", sans-serif;
  display: grid;
  place-items: center;
  height: 100vh;
  background: #7f7fd5;
  background: linear-gradient(to right, #91eae4, #86a8e7, #7f7fd5);
} */

.container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  max-width: 1200px;
  gap: 2rem;
  margin: auto;
}

img {
  max-width: 100%;
  display: block;
  object-fit: cover;
}

.card {
  display: flex;
  flex-direction: column;
  width: clamp(20rem, calc(20rem + 2vw), 22rem);
  overflow: hidden;
  box-shadow: 0 0.1rem 1rem rgba(0, 0, 0, 0.1);
  border-radius: 1em;
  background: #ece9e6;
  background: linear-gradient(to right, #ffffff, #ece9e6);
}

.card__body {
  padding: 1rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  margin-bottom: 30px;
}

.tag {
  align-self: flex-start;
  padding: 0.25em 0.75em;
  border-radius: 1em;
  font-size: 0.75rem;
}

.tag + .tag {
  margin-left: 0.5em;
}

.tag-blue {
  background: #56ccf2;
  background: linear-gradient(to bottom, #2f80ed, #56ccf2);
  color: #fafafa;
}

.tag-brown {
  background: #d1913c;
  background: linear-gradient(to bottom, #ffd194, #d1913c);
  color: #fafafa;
}

.tag-red {
  background: #cb2d3e;
  background: linear-gradient(to bottom, #ef473a, #cb2d3e);
  color: #fafafa;
}

.card__body h4 {
  font-size: 1.5rem;
  text-transform: capitalize;
}

.card__footer {
  display: flex;
  padding: 1rem;
  margin-top: auto;
}

.user {
  display: flex;
  gap: 0.5rem;
}

.user__image {
  border-radius: 50%;
}

.user__info > small {
  color: #666;
}
</style>
