<template>
  <div>
    <nav
      class="navbar navbar-expand-lg navbar-dark"
      style="
        background-color: rgb(190, 32, 24);
        position: fixed;
        z-index: 100;
        width: 100%;
      "
    >
      <div class="container-fluid">
        <a class="navbar-brand" href="">
          <img
            v-bind:src="URL_API + logo.imagen"
            :alt="logo.titulo"
            :width="logo.ancho"
            class="d-inline-block align-text-top"
          />
          <!-- Acá va el nombre-->
        </a>
        <button
          class="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
          @click="collapese"
        >
          <span class="navbar-toggler-icon"></span>
        </button>
        <div
          :class="
            collapse
              ? 'collapse navbar-collapse show'
              : 'collapse navbar-collapse'
          "
          id="navbarSupportedContent"
        >
          <ul
            class="navbar-nav me-auto mb-2 mb-lg-0"
            v-for="item in menu2"
            :key="item.id + 1"
          >
            <li class="nav-item" @click="collapese">
              <router-link
                class="nav-link active"
                v-if="item.submenu[0] == undefined"
                :to="item.link"
                >{{ item.title }}</router-link
              >
            </li>

            <li v-if="item.submenu[0] != undefined" class="nav-item dropdown">
              <a
                class="nav-link active dropdown-toggle"
                href="#"
                id="navbarDropdownMenuLink"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                {{ item.title }}
              </a>
              <ul
                class="dropdown-menu"
                aria-labelledby="navbarDropdownMenuLink"
                @click="collapese"
              >
                <li v-for="item2 in item.submenu" :key="item2.id">
                  <router-link class="dropdown-item" :to="'/' + item2.url">{{
                    item2.nombre_submenu
                  }}</router-link>
                </li>
              </ul>
            </li>
          </ul>
        </div>
        <div class="container p-4 pb-0">
          <!-- Section: Social media dinámica-->
          <section class="redes_menu mb-4">
            <a
              v-for="social in redes"
              :key="social.nombre"
              class="btn btn-floating m-1"
              style="color: white; font-size: 1.4rem"
              :href="social.url"
              role="button"
              target="_blank"
              ><i :class="social.icono"></i
            ></a>
          </section>
          <!-- Section: Social media dinámica-->
        </div>
      </div>
    </nav>
  </div>
</template>
<script>
// import { defineComponent } from '@vue/composition-api'
import axios from "axios";

export default {
  data() {
    return {
      menu2: [],
      menu:[],
      redes: [],
      logo:[],
      collapse:false,
       URL_API:process.env.VUE_APP_URL_API
    };
  },
  created() {
    this.getMenu();
    this.getRedesSociales();
    this.getLogoPagina();
  },
  methods: {
    collapese() {
      this.collapse = !this.collapse;
    },
    getMenu() {
      let self = this;
      axios
        .get(self.URL_API+"api/menupagina")
        .then(function (result) {
          self.menu = result.data;
          self.getSubMenu();
        })
        .catch(function (error) {
          console.log(error);
          self.getMenu()
        });
    },
    getSubMenu() {
      let self = this;
      axios
        .get(self.URL_API+"api/submenupagina")
        .then(function (result) {
          self.subMenu = result.data;
          self.menu.forEach(function (menu, index) {
            self.menu2.push({
              id: menu.id,
              title: menu.nombre,
              link: menu.url,
              submenu: [],
            });
            self.subMenu.forEach(function (submenu) {
              if (submenu.id_menu == menu.id) {
                self.menu2[index].submenu.push(submenu);
              }
            });
          });
          //  this.loaded = self.menu.length > 0 ? false : true;
        })
        .catch(function (error) {
          console.log(error);
          self.getSubMenu()
        });
    },
    getRedesSociales() {
      var self = this;
      axios
        .get(self.URL_API+"api/redessocialespagina")
        .then(function (result) {
          self.redes = result.data;
        })
        .catch(function (error) {
          console.log(error);
          self.getRedesSociales()
        });
    },
    getLogoPagina() {
      var self = this;
      axios
        .get(self.URL_API+"api/logopaginaactivo")
        .then(function (result) {
          self.logo = result.data[0];
        })
        .catch(function (error) {
          console.log(error);
          self.getLogoPagina()
        });
    },
  },
};
</script>
<style scoped>
#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}
.view {
  padding-top: 90px;
}
a {
  text-decoration: none;
}

li .nav-link {
  font-size: 1.1rem;
  white-space: nowrap;
}
@media screen and (max-width:1105px) {
  .redes_menu{
    display: none;
  }
}
</style>
