<template>
  <div>
    <Cardvertical :item="leyes" />
  </div>
</template>
<script>
import axios from "axios";
import Cardvertical from "../components/Cardvertical.vue";

export default {
  components: {
    Cardvertical,
  },
  data() {
    return {
      leyes: [],
      URL_API:process.env.VUE_APP_URL_API
    };
  },
  created() {
    this.getProyectosLey();
  },
  methods: {
    getProyectosLey() {
      var self = this;
      axios
        .get(self.URL_API+"api/leyespagina")
        .then(function (result) {
          self.leyes = result.data;
        })
        .catch(function (error) {
          console.log(error);
          self.getProyectosLey()
        });
    },
  },
};
</script>
<style scoped></style>
