<template>
  <div>
    <Cardvertical :item="noticias" />
    <div class="content">
      <nav class="pagin" aria-label="Page navigation example">
        <ul class="pagination">
          <li class="page-item">
            <a
              class="page-link"
              v-for="(item, index) in links.links"
              :key="index"
              @click="pagination(item.url)"
              >{{
                index == 0
                  ? "Anterior"
                  : index == siguiente - 1
                  ? "siguiente"
                  : index
              }}</a
            >
          </li>
        </ul>
      </nav>
    </div>
  </div>
</template>
<script>
import Cardvertical from "../components/Cardvertical.vue";
import axios from 'axios'

export default {
  components: {
    Cardvertical,
  },
  data() {
    return {
        noticias:[],
        links:'',
        siguiente:0,
        URL_API:process.env.VUE_APP_URL_API
    };
  },
  created() {
    this.getPost()
  },
  methods: {
    getPost() {
      let self = this;
      axios
        .get(self.URL_API+"api/noticias")
        .then(function (result) {
            self.noticias = result.data.data;
            self.links = result.data;
            self.siguiente = result.data.links.length;
        }).catch(function (error) {
          console.log(error);
          self.getPost()
        });
    },
    pagination(pag) {
      if (pag != null) {
        let self = this;
        axios.get(pag).then(function (result) {
            self.links = result.data;
            self.noticias = result.data.data;
        }).catch(function (error) {
          console.log(error);
        });
      }
    },
  },
};
</script>
<style scoped>
.pagination .page-item {
  display: flex;
  cursor: pointer;
  margin: 30px;
}
</style>
