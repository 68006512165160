<template>
  <Cardhorizontal :item="ponenencias" />
</template>
<script>
import Cardhorizontal from "../components/Cardhorizontal.vue";
import axios from 'axios'

export default {
  components:{
    Cardhorizontal
  },
  data() {
    return {
      ponenencias: [],
      URL_API:process.env.VUE_APP_URL_API
    };
  },
  created() {
    this.getPonencias();
  },
  methods: {
    getPonencias() {
      var self = this;
      axios
        .get(self.URL_API+"api/ponenciaspagina")
        .then(function (result) {
          self.ponenencias = result.data;
        })
        .catch(function (error) {
          console.log(error);
          self.getPonencias()
        });
    },
  },
};
</script>