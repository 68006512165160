<template>
  <div class="container">
    <h1>{{ titulo }}</h1>
    <div v-for="historia in biografia" :key="historia.id">
      <div class="imagenes" :style="historia.estilo_imagen">
        <img
          style="width: 100%"
          v-bind:src="URL_API + historia.imagen"
          alt=""
        />
      </div>
      <p class="contenido">{{ historia.contenido }}</p>
      <div style="clear: both"></div>
    </div>
  </div>
</template>
<script>
import axios from "axios";

export default {
  data() {
    return {
        biografia:[],
        titulo:'',
        URL_API:process.env.VUE_APP_URL_API
    };
  },
  created() {
    this.getBiografia()
  },
  methods: {
    getBiografia() {
      let self = this;
      axios
        .get(self.URL_API+"api/biografiapagina")
        .then(function (result) {
          self.biografia = result.data;
          self.titulo = result.data[0].titulo
        })
        .catch(function (error) {
          console.log(error);
          self.getBiografia()
        });
    },
  },
};
</script>
<style scoped>
.container {
  padding-top: 150px;
}
p {
  white-space: pre-wrap;
  white-space: -moz-pre-wrap;
  white-space: -o-pre-wrap;
  text-align: justify;
  margin-bottom: 30px;
  font-size: 1.2rem;  
}

@media screen and (max-width:992px) {

.container{
  padding: 150px 30px 30px 30px;
}

.imagenes{
  width: 100% !important;
  /* float: none !important;
  margin-right: 0px;
  margin-left: 0px; */
  
}

.imagenes img{
  width: 100%;
}

}
</style>