<template>
  <div>
    <!-- circle efects -->
    <div class="row">
      <div class="col-md-4" id="circle" v-for="circle in circles"
        :key="circle.id">
 
    <!-- normal -->
    <div :class="'ih-item circle effect3 '+circle.transicion"><router-link :to="circle.url">
        <div class="img"><img :src="URL_API+circle.imagen" alt="img"></div>
        <div class="info">
          <h3>{{ circle.titulo }}</h3>
          <!-- <p>Description goes here</p> -->
        </div></router-link></div>
    <!-- end normal -->
 
  </div>
    </div>
    <!-- fin circle efects -->
  </div>
</template>
<script>
import axios from "axios";

export default ({
  data() {
    return {
        circles:[],
        URL_API:process.env.VUE_APP_URL_API
    };
  },
  created() {
    this.getCircle();
  },
  methods: {
    getCircle() {
      var self = this;     
      axios
        .get(self.URL_API+"api/circulospagina")
        .then(function (result) {
          self.circles = result.data;
        })
        .catch(function (error) {
          console.log(error);
          self.getCircle()
        });
    },
  },
});
</script>
<style scoped>
.row {
  padding: 20px;
  width: 70%;
  margin: auto;
}
@media screen and (max-width:1022px) {
  #circle{
    width: 100%;
    margin-bottom: 30px;
  } 
}
@media screen and (max-width:552px) {
  #circle{
    display: none;
  } 
}
/* Circle efects */
.ih-item {
  position: relative;
  -webkit-transition: all 0.35s ease-in-out;
  -moz-transition: all 0.35s ease-in-out;
  transition: all 0.35s ease-in-out;
}
.ih-item,
.ih-item * {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.ih-item a {
  color: #333;
}
.ih-item a:hover {
  text-decoration: none;
}
.ih-item img {
  width: 100%;
  height: 100%;
}

.ih-item.circle {
  position: relative;
  width: 220px;
  height: 220px;
  border-radius: 50%;
}
.ih-item.circle .img {
  position: relative;
  width: 220px;
  height: 220px;
  border-radius: 50%;
}
.ih-item.circle .img:before {
  position: absolute;
  display: block;
  content: '';
  width: 100%;
  height: 100%;
  border-radius: 50%;
  box-shadow: inset 0 0 0 16px rgba(255, 255, 255, 0.6), 0 1px 2px rgba(0, 0, 0, 0.3);
  -webkit-transition: all 0.35s ease-in-out;
  -moz-transition: all 0.35s ease-in-out;
  transition: all 0.35s ease-in-out;
}
.ih-item.circle .img img {
  border-radius: 50%;
}
.ih-item.circle .info {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  text-align: center;
  border-radius: 50%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.ih-item.circle.effect3 .img {
  z-index: 11;
  -webkit-transition: all 0.35s ease-in-out;
  -moz-transition: all 0.35s ease-in-out;
  transition: all 0.35s ease-in-out;
}
.ih-item.circle.effect3.colored .info {
  background: #1a4a72;
}
.ih-item.circle.effect3 .info {
  background: #333333;
  opacity: 0;
  pointer-events: none;
  -webkit-transition: all 0.35s ease-in-out;
  -moz-transition: all 0.35s ease-in-out;
  transition: all 0.35s ease-in-out;
}
.ih-item.circle.effect3 .info h3 {
  color: #fff;
  text-transform: uppercase;
  position: relative;
  letter-spacing: 2px;
  font-size: 22px;
  margin: 0 30px;
  padding: 55px 0 0 0;
  height: 110px;
  text-shadow: 0 0 1px white, 0 1px 2px rgba(0, 0, 0, 0.3);
}
.ih-item.circle.effect3 .info p {
  color: #bbb;
  padding: 10px 5px;
  font-style: italic;
  margin: 0 30px;
  font-size: 12px;
  border-top: 1px solid rgba(255, 255, 255, 0.5);
}

.ih-item.circle.effect3.left_to_right .img {
  -webkit-transform: scale(1) translateX(0);
  -moz-transform: scale(1) translateX(0);
  -ms-transform: scale(1) translateX(0);
  -o-transform: scale(1) translateX(0);
  transform: scale(1) translateX(0);
}
.ih-item.circle.effect3.left_to_right .info {
  -webkit-transform: translateX(-100%);
  -moz-transform: translateX(-100%);
  -ms-transform: translateX(-100%);
  -o-transform: translateX(-100%);
  transform: translateX(-100%);
}
.ih-item.circle.effect3.left_to_right a:hover .img {
  -webkit-transform: scale(0.5) translateX(100%);
  -moz-transform: scale(0.5) translateX(100%);
  -ms-transform: scale(0.5) translateX(100%);
  -o-transform: scale(0.5) translateX(100%);
  transform: scale(0.5) translateX(100%);
}
.ih-item.circle.effect3.left_to_right a:hover .info {
  opacity: 1;
  -webkit-transform: translateX(0);
  -moz-transform: translateX(0);
  -ms-transform: translateX(0);
  -o-transform: translateX(0);
  transform: translateX(0);
}

.ih-item.circle.effect3.right_to_left .img {
  -webkit-transform: scale(1) translateX(0);
  -moz-transform: scale(1) translateX(0);
  -ms-transform: scale(1) translateX(0);
  -o-transform: scale(1) translateX(0);
  transform: scale(1) translateX(0);
}
.ih-item.circle.effect3.right_to_left .info {
  -webkit-transform: translateX(100%);
  -moz-transform: translateX(100%);
  -ms-transform: translateX(100%);
  -o-transform: translateX(100%);
  transform: translateX(100%);
}
.ih-item.circle.effect3.right_to_left a:hover .img {
  -webkit-transform: scale(0.5) translateX(-100%);
  -moz-transform: scale(0.5) translateX(-100%);
  -ms-transform: scale(0.5) translateX(-100%);
  -o-transform: scale(0.5) translateX(-100%);
  transform: scale(0.5) translateX(-100%);
}
.ih-item.circle.effect3.right_to_left a:hover .info {
  opacity: 1;
  -webkit-transform: translateX(0);
  -moz-transform: translateX(0);
  -ms-transform: translateX(0);
  -o-transform: translateX(0);
  transform: translateX(0);
}

.ih-item.circle.effect3.top_to_bottom .img {
  -webkit-transform: scale(1) translateY(0);
  -moz-transform: scale(1) translateY(0);
  -ms-transform: scale(1) translateY(0);
  -o-transform: scale(1) translateY(0);
  transform: scale(1) translateY(0);
}
.ih-item.circle.effect3.top_to_bottom .info {
  -webkit-transform: translateY(-100%);
  -moz-transform: translateY(-100%);
  -ms-transform: translateY(-100%);
  -o-transform: translateY(-100%);
  transform: translateY(-100%);
}
.ih-item.circle.effect3.top_to_bottom a:hover .img {
  -webkit-transform: scale(0.5) translateY(100%);
  -moz-transform: scale(0.5) translateY(100%);
  -ms-transform: scale(0.5) translateY(100%);
  -o-transform: scale(0.5) translateY(100%);
  transform: scale(0.5) translateY(100%);
}
.ih-item.circle.effect3.top_to_bottom a:hover .info {
  opacity: 1;
  -webkit-transform: translateY(0);
  -moz-transform: translateY(0);
  -ms-transform: translateY(0);
  -o-transform: translateY(0);
  transform: translateY(0);
}

.ih-item.circle.effect3.bottom_to_top .img {
  -webkit-transform: scale(1) translateY(0);
  -moz-transform: scale(1) translateY(0);
  -ms-transform: scale(1) translateY(0);
  -o-transform: scale(1) translateY(0);
  transform: scale(1) translateY(0);
}
.ih-item.circle.effect3.bottom_to_top .info {
  -webkit-transform: translateY(100%);
  -moz-transform: translateY(100%);
  -ms-transform: translateY(100%);
  -o-transform: translateY(100%);
  transform: translateY(100%);
}
.ih-item.circle.effect3.bottom_to_top a:hover .img {
  -webkit-transform: scale(0.5) translateY(-100%);
  -moz-transform: scale(0.5) translateY(-100%);
  -ms-transform: scale(0.5) translateY(-100%);
  -o-transform: scale(0.5) translateY(-100%);
  transform: scale(0.5) translateY(-100%);
}
.ih-item.circle.effect3.bottom_to_top a:hover .info {
  opacity: 1;
  -webkit-transform: translateY(0);
  -moz-transform: translateY(0);
  -ms-transform: translateY(0);
  -o-transform: translateY(0);
  transform: translateY(0);
}

/* Fin ircle efects */
</style>
