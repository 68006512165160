<template>
  <div>
    <Cardvertical :item="proyectosLey"/>
  </div>
</template>
<script>
import axios from 'axios'
import Cardvertical from "../components/Cardvertical.vue";

export default {
  components: {
    Cardvertical,
  },
  data() {
    return {
        proyectosLey:[],
        URL_API:process.env.VUE_APP_URL_API
    };
  },
  created() {
    this.getProyectosLey()
  },
  methods: {
    getProyectosLey() {
      var self = this;
      axios
        .get(self.URL_API+"api/proyectosleypagina")
        .then(function (result) {
          self.proyectosLey = result.data;
        })
        .catch(function (error) {
          console.log(error);
          self.getProyectosLey()
        });
    },
  },
};
</script>
<style scoped>
.container {
  padding-top: 150px;
}
h1 {
  font-size: 1.5rem;
  margin-top: 30px;
}
p {
  text-align: justify;
}
a {
  text-decoration: none;
  text-align: left;
}
/********************************************/
@import url("https://fonts.googleapis.com/css2?family=Quicksand:wght@300..700&display=swap");

*,
*::before,
*::after {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

/* body {
  font-family: "Quicksand", sans-serif;
  display: grid;
  place-items: center;
  height: 100vh;
  background: #7f7fd5;
  background: linear-gradient(to right, #91eae4, #86a8e7, #7f7fd5);
} */

.container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  max-width: 1200px;
  gap: 2rem;
  margin: auto;
}

img {
  max-width: 100%;
  display: block;
  object-fit: cover;
}

.card {
  display: flex;
  flex-direction: column;
  width: clamp(20rem, calc(20rem + 2vw), 22rem);
  overflow: hidden;
  box-shadow: 0 0.1rem 1rem rgba(0, 0, 0, 0.1);
  border-radius: 1em;
  background: #ece9e6;
  background: linear-gradient(to right, #ffffff, #ece9e6);
}

.card__body {
  padding: 1rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.tag {
  align-self: flex-start;
  padding: 0.25em 0.75em;
  border-radius: 1em;
  font-size: 0.75rem;
}

.tag + .tag {
  margin-left: 0.5em;
}

.tag-blue {
  background: #56ccf2;
  background: linear-gradient(to bottom, #2f80ed, #56ccf2);
  color: #fafafa;
}

.tag-brown {
  background: #d1913c;
  background: linear-gradient(to bottom, #ffd194, #d1913c);
  color: #fafafa;
}

.tag-red {
  background: #cb2d3e;
  background: linear-gradient(to bottom, #ef473a, #cb2d3e);
  color: #fafafa;
}

.card__body h4 {
  font-size: 1.5rem;
  text-transform: capitalize;
}

.card__footer {
  display: flex;
  padding: 1rem;
  margin-top: auto;
}

.user {
  display: flex;
  gap: 0.5rem;
}

.user__image {
  border-radius: 50%;
}

.user__info > small {
  color: #666;
}
</style>
