<template>
  <div>
      <footer class="bg-light text-center text-white">
          <!-- Grid container -->
          <div class="container p-4 pb-0">
            <p style="color: black">{{footer.texto1}}</p>
            <!-- Section: Social media dinámica-->
            <section class="mb-4">
              <a
                v-for="social in redes"
                :key="social.nombre"
                class="btn btn-floating m-1"
                :style="'background-color:' + social.color"
                :href="social.url"
                role="button"
                target="_blank"
                ><i :class="social.icono"></i
              ></a>
            </section>
            <!-- Section: Social media dinámica-->
          </div>
          <!-- Grid container -->

          <!-- Copyright -->
          <div class="text-center p-3" id="pie">
            © {{ year }} {{footer.texto2}}
            <!-- <a class="text-white" href="https://solusistem.com.co/">Design by: Solusistem</a> -->
          </div>
          <div class="text-center" style="height:56px;  background-color: rgba(0, 0, 0, 0.3);border-top: #CCC6C5 0.5px solid;">
            <a class="text-black" href="https://solusistem.com.co" target="_blank">{{footer.texto3}} <img style="width:150px;margin:0;padding:0" v-bind:src="URL_API+footer.imagen" alt=""></a>
          </div>
          <!-- Copyright -->
        </footer>
    <!-- fin footer -->
  </div>
</template>
<script>
import axios from "axios";

export default {
  data() {
    return {
        redes:[],
        footer:[],
        URL_API:process.env.VUE_APP_URL_API
    };
  },
  created() {
    this.getRedesSociales()
    this.getFooter()
    this.currentYear()
  },
  methods: {
    currentYear() {
      var currentTime = new Date();
      this.year = currentTime.getFullYear();
    },
    getRedesSociales() {
      var self = this;
      axios
        .get(self.URL_API+"api/redes")
        .then(function (result) {
          self.redes = result.data;
        })
        .catch(function (error) {
          console.log(error);
          self.getRedesSociales()
        });
    },
    getFooter() {
      var self = this;
      axios
        .get(self.URL_API+"api/footerpagina")
        .then(function (result) {
          self.footer = result.data[0];
        })
        .catch(function (error) {
          console.log(error);
          self.getFooter()
        });
    },
  },
};
</script>
<style scoped>

.text-center p-3 {
  clear: both;
}

.bg-light {
  margin-top: 30px;
  clear: both !important;
}

#pie {
  background-color: rgba(77, 75, 75, 0.3);
  clear: both;
  color:black
}
a {
  text-decoration: none;
  color: white;
}
@media screen and (max-width:1105px) {
  .redes_menu{
    display: none;
  }
}
</style>
