<template>
  <div class="container">
    <div class="row row-cols-1 row-cols-md-3 g-4">
      <div class="col" v-for="item in item" :key="item.title">
        <div class="card h-100">
          <iframe
            v-if="item.url != ''"
            :src="item.url"
            frameborder="0"
            allow="accelerometer; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
          <img
            v-if="item.url == ''"
            v-bind:src="URL_API + item.imagen"
            class="card-img-top"
            alt="..."
          />
          <div class="card-body">
            <p class="card-text">
              {{ item.titulo }}
            </p>
          </div>
          <div class="card-footer">
            <!-- <button class="btn btn-primary">ver mas...</button> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    item: [],
  },
  data() {
    return {
        URL_API:process.env.VUE_APP_URL_API
    };
  },
  created() {},
  methods: {},
};
</script>
<style scoped></style>
