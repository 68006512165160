<template>
  <div>
    <div class="peinado" v-for="item in biografia" :key="item.id">
      <img v-bind:src="URL_API + item.imagen" alt="" />
      <div class="perfil">
        <p>
          {{ item.contenido }}
        </p>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";

export default {
  data() {
    return {
      biografia: [],
      URL_API:process.env.VUE_APP_URL_API
    };
  },
  created() {
    this.getBiografia();
  },
  methods: {
    getBiografia() {
      var self = this;
      axios
        .get(self.URL_API+"api/biografiacorta")
        .then(function (result) {
          self.biografia = result.data;
        })
        .catch(function (error) {
          console.log(error);
          self.getBiografia()
        });
    },
  },
};
</script>
<style scoped>
.peinado {
  width: 90%;
  margin-left: 30px;
  margin: auto;
  max-width: 1500px;
}
.peinado img {
  width: 20%;
  float: left;
}
.perfil {
  width: 80%;
  float: left;
  padding: 39.2px;
  text-align: justify;
}
.perfil p{
  /* font-family: 'Open Sans', sans-serif; */
  font-size: 1.2rem;
}

@media screen and (max-width:759px) {

  .peinado{
    width: 80%;
  }

  .peinado img{
    width: 80%;
    margin: 0px;
    padding: 0px;
    margin-bottom: 30px;
  } 

  .peinado .perfil{
    width: 100%;
    margin: 0px;
    padding: 0px;
  }
}
</style>
