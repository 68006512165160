<template>
  <Cardhorizontal :item="proyectosAntioquia" />
</template>
<script>
import Cardhorizontal from "../components/Cardhorizontal.vue";
import axios from 'axios'

export default {
  components:{
    Cardhorizontal
  },
  data() {
    return {
      proyectosAntioquia: [],
      URL_API:process.env.VUE_APP_URL_API
    };
  },
  created() {
    this.getProyectosLey();
  },
  methods: {
    getProyectosLey() {
      var self = this;
      axios
        .get(self.URL_API+"api/proyectosantioquiapagina")
        .then(function (result) {
          self.proyectosAntioquia = result.data;
        })
        .catch(function (error) {
          console.log(error);
          self.getProyectosLey()
        });
    },
  },
};
</script>
