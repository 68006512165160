<template>
  <div>
  <div id="carouselExampleCaptions" class="carousel slide" data-bs-ride="false">
  <div class="carousel-indicators">
    <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></button>
    <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="1" aria-label="Slide 2"></button>
    <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="2" aria-label="Slide 3"></button>
  </div>
  <div class="carousel-inner">
    <div class="carousel-item active">
      <img v-bind:src="URL_API+slider[0].imagen" class="d-block w-100" alt="...">
      <div class="carousel-caption d-none d-md-block">
        <!-- <h5>First slide label</h5>
        <p>Some representative placeholder content for the first slide.</p> -->
      </div>
    </div>
    <div class="carousel-item">
      <img v-bind:src="URL_API+slider[1].imagen" class="d-block w-100" alt="...">
      <div class="carousel-caption d-none d-md-block">
        <!-- <h5>Second slide label</h5>
        <p>Some representative placeholder content for the second slide.</p> -->
      </div>
    </div>
    <div class="carousel-item">
      <img v-bind:src="URL_API+slider[2].imagen" class="d-block w-100" alt="...">
      <div class="carousel-caption d-none d-md-block">
        <!-- <h5>Third slide label</h5>
        <p>Some representative placeholder content for the third slide.</p> -->
      </div>
    </div>
  </div>
  <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="prev">
    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
    <span class="visually-hidden">Previous</span>
  </button>
  <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="next">
    <span class="carousel-control-next-icon" aria-hidden="true"></span>
    <span class="visually-hidden">Next</span>
  </button>
</div>
  </div>
</template>
<script>
import axios from 'axios'
export default {
  data() {
    return {
        slider:[],
        URL_API:process.env.VUE_APP_URL_API
    };
  },
  created() {
    this.getSlider()
  },
  methods: {
    getSlider() {
      var self = this;
      axios
        .get(self.URL_API+"api/sliderpagina")
        .then(function (result) {
          self.slider = result.data;
        })
        .catch(function (error) {
          console.log(error);
          self.getSlider()
        });
    },
  },
};
</script>
<style scoped>
.carousel {
  width: 98%;
  margin: auto;
  padding-top: 20px;
  border-radius: 20px;
  overflow: hidden;
}
@media screen and (min-width: 2000px) {
  .carousel {
    height: 1200px;
  }
}
.carousel-inner .carousel-item img {
  border-radius: 10px;
}

@media screen and (max-width:552px) {
   .carousel {
    padding-top: 130px;
  }
}
</style>
