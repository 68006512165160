<template>
  <div>
    <Cardhorizontal :item="noticia" />
  </div>
</template>
<script>
import Cardhorizontal from "../components/Cardhorizontal.vue";
import axios from "axios";

export default {
    components:{
        Cardhorizontal
    },
  data() {
    return {
        noticia:[],
        id:'',
        URL_API:process.env.VUE_APP_URL_API
    };
  },
  created() {
    this.getNoticia()
  },
  methods: {
    getNoticia() {
      var self = this;
      axios
        .get(
          self.URL_API+"api/noticiaporidpagina/"+this.$route.params.id
        )
        .then(function (result) {
          self.noticia = result.data;
        })
        .catch(function (error) {
          console.log(error);
          self.getNoticia()
        });
    },
  },
};
</script>
