<template>
  <div>
    <div v-for="item in parallax" :key="item.id"
      class="parallax"
      v-bind:style="'background-image: url(' + URL_API + item.imagen + ')'"
    ></div>
      <!-- v-bind:style="{width:item.ancho, height:item.alto}" -->
  </div>
</template>
<script>
import axios from "axios";
export default {
  data() {
    return {
      parallax: [],
      URL_API:process.env.VUE_APP_URL_API
    };
  },
  created() {
    this.getParallax();
  },
  methods: {
    getParallax() {
      var self = this;
      axios
        .get(self.URL_API+"api/parallaxpagina")
        .then(function (result) {
          self.parallax = result.data;
        })
        .catch(function (error) {
          console.log(error);
          self.getParallax()
        });
    },
  },
};
</script>
<style scoped>
.parallax {
  width: 100%;
  height: 350px;
  overflow: hidden;
  padding-top: 100px;
  background-size: cover;
  background-attachment: fixed;
  background-color: rgba(255, 255, 255, 0.5);
  background-size: 100%;
  background-position-y: 100px;
  background-repeat: no-repeat;
}

@media screen and (max-width:552px) {
   .parallax {
    height: 150px;
  }
}
</style>
