<template>
  <div>
    <!-- inicio tarjetas  -->
    <div class="wrapper">
      <div class="card" v-for="frame in frames" :key="frame.id">
        <input
          type="checkbox"
          :id="'card'+(frame.id+1)"
          class="more"
          aria-hidden="true"
        />
        <div class="content">
          <div
            class="front"
            v-bind:style="
              'background-image: url(' +
              URL_API +
              frame.imagen +
              ')'
            "
          >
            <div class="inner">
              <h2>{{ frame.titulo }}</h2>
              <label :for="'card'+(frame.id+1)" class="button" aria-hidden="true">
                Ver más
              </label>
            </div>
          </div>
          <div class="back">
            <div class="inner">
              <div class="description">
                <p>{{ frame.contenido }}</p>
              </div>
              <label
                :for="'card'+(frame.id+1)"
                class="button return"
                aria-hidden="true"
              >
                <i class="fas fa-arrow-left"></i>
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- fin tarjetas -->
  </div>
</template>
<script>
import axios from 'axios'
export default {
  data() {
    return {
      frames: [],
      URL_API:process.env.VUE_APP_URL_API
    };
  },
  created() {
    this.getFrame();
  },
  methods: {
    getFrame() {
      var self = this;
      axios
        .get(self.URL_API+"api/cubospagina")
        .then(function (result) {
          self.frames = result.data;
        })
        .catch(function (error) {
          console.log(error);
          self.getFrame()
        });
    },
  },
};
</script>
<style scoped>
/* Inicio Box Efects */
/* *, *:after, *:before { -webkit-box-sizing: border-box; box-sizing: border-box; } // required */
.grid {
  position: relative;
  clear: both;
  margin: 0 auto;
  padding: 1em 0 4em;
  max-width: 1000px;
  list-style: none;
  text-align: center;
}

/* Common style */
.grid figure {
  position: relative;
  /* float: left; */
  overflow: hidden;
  margin: 10px 1%;
  min-width: 320px;
  max-width: 480px;
  max-height: 360px;
  width: 48%;
  height: auto;
  background: #3085a3;
  text-align: center;
  cursor: pointer;
}

.grid figure img {
  position: relative;
  display: block;
  min-height: 100%;
  max-width: 100%;
  opacity: 0.8;
}

.grid figure figcaption {
  padding: 2em;
  color: #fff;
  text-transform: uppercase;
  font-size: 1.25em;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.grid figure figcaption::before,
.grid figure figcaption::after {
  pointer-events: none;
}

.grid figure figcaption,
.grid figure figcaption > a {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

/* Anchor will cover the whole item by default */
.grid figure figcaption > a {
  z-index: 1000;
  text-indent: 200%;
  white-space: nowrap;
  font-size: 0;
  opacity: 0;
}

.grid figure h2 {
  word-spacing: -0.15em;
  font-weight: 300;
}

.grid figure h2 span {
  font-weight: 800;
}

.grid figure h2,
.grid figure p {
  margin: 0;
}

.grid figure p {
  letter-spacing: 1px;
  font-size: 68.5%;
}

/* Individual effects */

/*---------------*/
/***** Julia *****/
/*---------------*/

figure.effect-julia {
  background: #2f3238;
}

figure.effect-julia img {
  max-width: none;
  height: 400px;
  -webkit-transition: opacity 1s, -webkit-transform 1s;
  transition: opacity 1s, transform 1s;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

figure.effect-julia figcaption {
  text-align: left;
}

figure.effect-julia h2 {
  position: relative;
  padding: 0.5em 0;
}

figure.effect-julia p {
  display: inline-block;
  margin: 0 0 0.25em;
  padding: 0.4em 1em;
  background: rgba(255, 255, 255, 0.9);
  color: #2f3238;
  text-transform: none;
  font-weight: 500;
  font-size: 75%;
  -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
  transition: opacity 0.35s, transform 0.35s;
  -webkit-transform: translate3d(-360px, 0, 0);
  transform: translate3d(-360px, 0, 0);
}

figure.effect-julia p:first-child {
  -webkit-transition-delay: 0.15s;
  transition-delay: 0.15s;
}

figure.effect-julia p:nth-of-type(2) {
  -webkit-transition-delay: 0.1s;
  transition-delay: 0.1s;
}

figure.effect-julia p:nth-of-type(3) {
  -webkit-transition-delay: 0.05s;
  transition-delay: 0.05s;
}

figure.effect-julia:hover p:first-child {
  -webkit-transition-delay: 0s;
  transition-delay: 0s;
}

figure.effect-julia:hover p:nth-of-type(2) {
  -webkit-transition-delay: 0.05s;
  transition-delay: 0.05s;
}

figure.effect-julia:hover p:nth-of-type(3) {
  -webkit-transition-delay: 0.1s;
  transition-delay: 0.1s;
}

figure.effect-julia:hover img {
  opacity: 0.4;
  -webkit-transform: scale3d(1.1, 1.1, 1);
  transform: scale3d(1.1, 1.1, 1);
}

figure.effect-julia:hover p {
  opacity: 1;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}
/* Fin Box Efects */

.box {
  width: 100%;
  max-width: 2000px;
  margin: auto;
}

.parallax {
  width: 100%;
  height: 350px;
  overflow: hidden;
  padding-top: 100px;
  background-size: cover;
  background-attachment: fixed;
  background-color: rgba(255, 255, 255, 0.5);
  background-size: 100%;
  background-position-y: 100px;
}

/**************** Tarjetas************************/

body {
  margin: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  font-family: "Montserrat", sans-serif;
}
.wrapper {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  clear: both;
}
.card {
  width: 420px;
  height: 340px;
  margin: 1em;
  perspective: 1500px;
  border: 0px;
}
.card .content {
  position: relative;
  width: 100%;
  height: 100%;
  transform-style: preserve-3d;
  transition: transform 0.8s cubic-bezier(0.75, 0, 0.85, 1);
}
.more {
  display: none;
}
.more:checked ~ .content {
  transform: rotateY(180deg);
}
.front,
.back {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  transform-style: preserve-3d;
  border-radius: 6px;
}
.front .inner,
.back .inner {
  height: 100%;
  display: grid;
  padding: 1.5em;
  transform: translateZ(80px) scale(0.94);
}
.front {
  background-color: #fff;
  background-size: cover;
  background-position: center center;
}
.front:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: block;
  border-radius: 6px;
  backface-visibility: hidden;
  background: linear-gradient(
    40deg,
    rgba(67, 138, 243, 0.3),
    rgba(255, 242, 166, 0.3)
  );
}
.front .inner {
  grid-template-rows: 5fr 1fr 1fr 2fr 1fr;
  justify-items: center;
}
.front h2 {
  grid-row: 2;
  margin-bottom: 0.3em;
  text-transform: uppercase;
  letter-spacing: 3px;
  color: #fff;
  font-weight: 500;
  text-shadow: 0 0 6px rgba(0, 0, 0, 0.1);
}

.back {
  transform: rotateY(180deg);
  background-color: #fff;
  border: 2px solid #f0f0f0;
}
.back .inner {
  grid-template-rows: 1fr 2fr 1fr 2fr 14fr 1fr 1fr;
  grid-template-columns: repeat(4, auto);
  grid-column-gap: 0.8em;
  justify-items: center;
}
.back .info {
  position: relative;
  display: flex;
  align-items: center;
  color: #355cc9;
  grid-row: 3;
}
.back .info:not(:first-of-type):before {
  content: "";
  position: absolute;
  left: -0.9em;
  height: 18px;
  width: 1px;
  background-color: #ccc;
}
.back .info span {
  font-size: 2em;
  font-weight: 700;
}
.back .info i {
  font-size: 1.2em;
}
.back .info i:before {
  background: linear-gradient(40deg, #355cc9, #438af3);
  -webkit-text-fill-color: transparent;
  /*TODO: -webkit-background-clip: text; */
}

.back .description {
  font-size: 0.86em;
  line-height: 1.4em;
  width: 100%;
  height: 250px;
  overflow: auto;
  padding: 10px;
  color: #000000;
  text-align: justify;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
}

.back .button {
  width: 100%;
  justify-self: center;
}
.button {
  grid-row: -1;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: 600;
  cursor: pointer;
  display: block;
  padding: 0 1.5em;
  height: 3em;
  line-height: 2.9em;
  min-width: 3em;
  background-color: transparent;
  border: solid 2px #fff;
  color: #fff;
  border-radius: 4px;
  text-align: center;
  left: 50%;
  backface-visibility: hidden;
  transition: 0.3s ease-in-out;
  text-shadow: 0 0 6px rgba(0, 0, 0, 0.3);
}
.button:hover {
  background-color: #fff;
  box-shadow: 0 0 50px rgba(0, 0, 0, 0.5);
  text-shadow: none;
  color: #000000;
}
.button.return {
  line-height: 3em;
  color: #000000;
  border-color: #000000;
  text-shadow: none;
}
.button.return:hover {
  background-color: #f51010;
  color: rgb(255, 255, 255);
  box-shadow: none;
}
::-webkit-scrollbar {
  width: 5px;
}
::-webkit-scrollbar-track {
  background: #f1f1f1;
}
::-webkit-scrollbar-thumb {
  background: #080808;
}
::-webkit-scrollbar-thumb:hover {
  background: #ee1515;
}
</style>
