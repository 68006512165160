<template>
  <div>
    <Slider />
    <Circulo />
    <Parallax />
    <Biografiacorta />
    <h2 v-if="noticias.length > 0">Noticias</h2>
    <Cardvertical :item="noticias" />
    <Cubos />
  </div>
</template>
<script>
import axios from "axios";
import Slider from "../components/Slider.vue";
import Circulo from "../components/Circulo.vue";
import Parallax from "../components/Parallax.vue";
import Biografiacorta from "../components/Biografiacorta.vue";
import Cubos from "../components/Cubos.vue";
import Cardvertical from "../components/Cardvertical.vue";

export default {
  components: {
    Slider,
    Circulo,
    Parallax,
    Biografiacorta,
    Cubos,
    Cardvertical,
  },
  data() {
    return {
      noticias: [],
      URL_API: process.env.VUE_APP_URL_API,
    };
  },
  created() {
    this.getPost();
  },
  methods: {
    getPost() {
      let self = this;
      axios
        .get(self.URL_API + "api/postspagina")
        .then(function (result) {
          self.noticias = result.data;
        })
        .catch(function (error) {
          console.log(error);
          // self.getPost();
        });
    },
  },
};
</script>
<style scoped>
h2 {
  margin: auto;
  text-align: center;
  clear: both;
  background-color: rgb(238, 26, 26);
  width: 20%;
  border-radius: 15px;
  color: white;
  min-width: 300px;
}


</style>
