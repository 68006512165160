<template>
  <div>
    <Cardvideo :item="audiencias" />
  </div>
</template>
<script>
import axios from 'axios'
import Cardvideo from "../components/Cardvideo.vue";

export default {
  components: {
    Cardvideo,
  },
  data() {
    return {
      audiencias: [],
      URL_API:process.env.VUE_APP_URL_API
    };
  },
  created() {
    this.getAudiencias();
  },
  methods: {
    getAudiencias() {
      var self = this;
      axios
        .get(self.URL_API+"api/audienciaspagina")
        .then(function (result) {
          self.audiencias = result.data;
        })
        .catch(function (error) {
          console.log(error);
          self. getAudiencias()
        });
    },
  },
};
</script>
<style scoped>
.container {
  padding-top: 150px;
}
h1 {
  font-size: 1.5rem;
  margin-top: 30px;
}
p {
  text-align: justify;
}
</style>
